<template>
    <div class="purchase">
        <div class="banner-image primary pt-16 px-6 pb-6">
            <v-btn text color="dark" class="zindex-2 p-fixed top20 left5 pa-1" :to="{ name: 'modules' }" v-if="$vuetify.breakpoint.xsOnly">
                <v-icon>mdi-chevron-left</v-icon>
                Back
            </v-btn>

            <img class="mt-6 logo" src="@/assets/glf-logo-full.png" />
        </div>

        <v-container style="max-width: 700px; margin: auto" :class="{ 'pa-16': $vuetify.breakpoint.mdAndUp }">
            <v-row v-if="$vuetify.breakpoint.smAndUp">
                <v-col cols="12">
                    <v-btn text color="dark" :to="{ name: 'modules' }">
                        <v-icon>mdi-chevron-left</v-icon>
                        Back
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" align="center" justify="center">
                    <h2 class="heading--text mb-4">Global Leadership Foundation</h2>

                    <p class="ma-0">
                        We hope you're enjoying: <b>Mastering Emotional Health — 21 ways to enhance your wellbeing</b>! If you would like to
                        purchase the rest of the bundle, please do so below.
                    </p>
                </v-col>
            </v-row>

            <!-- <v-row>
            <v-col cols="6" sm="6">
                <meo-card-item :item="bundle" @click.native="goToBundle()"></meo-card-item>
            </v-col>

            <v-col cols="6" sm="6">
                <meo-card-item :item="item" @click.native="goToModule()"></meo-card-item>
            </v-col>
        </v-row> -->

            <form @submit.prevent="submit">
                <v-row>
                    <v-col cols="12">
                        <v-card class="pa-2 light-grey elevation-0 payment d-flex align-center justify-space-between" style="height: 56px">
                            <div class="d-flex align-center">
                                <v-icon class="mr-2" color="primary">mdi-credit-card-outline</v-icon>
                                <h4>Amount Due:</h4>
                            </div>

                            <h4>{{ bundle.amount | formatCurrency }} <span style="font-weight:300">(AUD)</span></h4>
                        </v-card>
                    </v-col>
                </v-row>

                <meo-stripe v-if="stripe" ref="stripeElement" :stripe="stripe"></meo-stripe>

                <v-row>
                    <v-col cols="12">
                        <v-btn block x-large dark color="dark" type="submit" :loading="loading"
                            >Confirm purchase
                            <v-icon class="ml-2" color="warning">mdi-credit-card-outline</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </form>
        </v-container>
    </div>
</template>

<script>
// Mixins
import UserMixin from "@/mixins/user-mixin";
import SEOMixin from "@/mixins/seo-mixin";

// Components
import MeoCardItem from "@/components/misc/meo-card-item.vue";
import MeoStripe from "@/components/misc/meo-stripe.vue";

// Services
import Module from "@/modules/meo-module/module";

export default {
    name: "purchase",
    mixins: [SEOMixin],
    meta: { titleTemplate: "Purchase | %s" },

    components: {
        MeoCardItem,
        MeoStripe,
    },

    data() {
        return {
            loading: false,
            bundle: {},
            item: {},
            stripe: null,
            integration: null,
            rules: {
                required: (value) => !!value || "This field is required",
                email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail";
                },
            },
        };
    },

    filters: {
        formatCurrency(input) {
            let value = input;

            value = "$" + (value / 100).toFixed(2);

            return value;
        },
    },

    computed: {
        title() {
            return (this.item && this.item.data && this.item.data.displayTitle) || this.item.title;
        },
    },

    methods: {
        async init() {
            this.$meo.loading = true;

            this.bundle = await Module.get(this.$meo.bundle).then(({ data }) => data);
            // this.item = await Module.get(this.$route.params.id).then(({ data }) => data);

            this.integration = await this.$fluro.api.get(`/content/integration/${this.$meo.integration}`).then(({ data }) => data);
            let key = this.integration.publicDetails.livePublicKey;

            if (this.integration.publicDetails.sandbox) {
                key = this.integration.publicDetails.testPublicKey;
            }

            this.stripe = window.Stripe(key);

            this.$meo.loading = false;
        },
        async submit() {
            this.loading = true;

            let card = this.$refs.stripeElement.getCard();
            let source = await this.stripe.createToken(card.cardNumber).then(({ token }) => token.id);

            let payload = {
                product: this.bundle._id,
                integration: this.integration._id,
                payment: {
                    source,
                },
            };

            await this.$fluro.api
                .post("/payment/purchase", payload)
                .then((res) => {
                    console.log("PAYMENT SUCCESS", res.data);

                    this.$router.push({ name: "modules" });
                    this.loading = false;
                })
                .catch((err) => {
                    console.log("PAYMENT ERROR", err);

                    this.loading = false;
                });
        },
        // goToBundle() {
        //     this.$router.push({ name: 'purchaseBundle' });
        // },
        // goToModule() {
        //     this.$router.push({ name: 'purchaseIndividual', params: { id: this.item._id } });
        // }
    },

    async mounted() {
        await this.init();
    },
};
</script>

<style lang="scss">
.purchase {
    .logo {
        width: 100%;
        max-width: 500px;
        display: block;
        margin: auto;
    }
}
</style>
