import FluroContent from './fluro-content';

class MeoModule extends FluroContent {
    constructor() {
        super('meoModule')
    }

    list(options) {
        return super.list(options);
    }

    get(id, options) {
        return super.get(id, options);
    }

    create(payload, options) {
        return super.create(payload, options);
    }

    update(id, payload, options) {
        return super.update(id, payload, options);
    }

    delete(id, options) {
        return super.delete(id, options);
    }
}

const _MeoModule = new MeoModule();
export default _MeoModule;