<template>
<v-row>
    <v-col cols="12">
        <div id="stripe-card-number" class="field empty"></div>
    </v-col>

    <v-col cols="12" sm="6">
        <div id="stripe-card-expiry" class="field empty"></div>
    </v-col>

    <v-col cols="12" sm="6">
        <div id="stripe-card-cvc" class="field empty"></div>
    </v-col>
</v-row>
</template>

<script>
export default {
    props: {
        stripe: Object
    },
    data() {
        return {
            card: {}
        }
    },
    methods: {
        init() {
            var elements = this.stripe.elements()
            var elementStyles = {
                base: {
                    color: '#32325D',
                    fontWeight: 500,
                    // fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
                    // fontSize: '16px',
                    fontSmoothing: 'antialiased',

                    '::placeholder': {
                        color: '#CCCCCC',
                    },
                    ':-webkit-autofill': {
                        color: '#e39f48',
                    },
                },
                invalid: {
                    color: '#E25950',

                    '::placeholder': {
                        color: '#FFCCA5',
                    },
                },
            };

            var elementClasses = {
                focus: 'focused',
                empty: 'empty',
                invalid: 'invalid',
            };

            var cardNumber = elements.create('cardNumber', {
                style: elementStyles,
                classes: elementClasses,
            });
            cardNumber.mount('#stripe-card-number');

            var cardExpiry = elements.create('cardExpiry', {
                style: elementStyles,
                classes: elementClasses,
            });
            cardExpiry.mount('#stripe-card-expiry');

            var cardCvc = elements.create('cardCvc', {
                style: elementStyles,
                classes: elementClasses,
            });
            cardCvc.mount('#stripe-card-cvc');

            this.card = {
                cardNumber,
                cardExpiry,
                cardCvc
            }

            console.log('initialised', this.card)
        },
        getCard() {
            return this.card
        }
    },
    mounted() {
        this.init()
    }
}
</script>

<style lang="scss">
.field {
    padding: 8px;
    border-radius: 4px;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    border: 1px solid var(--v-dark-grey-base);
    transition: all 0.2s;
    background: var(--v-light-base);

    &.focused {
        border: 2px solid var(--v-dark-base);
    }

    &.invalid {
        border: 2px solid var(--v-error-base);
    }
}

.__PrivateStripeElement {
    width: 100%;
}

.field + .field {
    margin-top: 6px;
}

input::-webkit-input-placeholder {
    color: #9bacc8;
}

input::-moz-placeholder {
    color: #9bacc8;
}

input:-ms-input-placeholder {
    color: #9bacc8;
}

.error svg .base {
    fill: #fa755a;
}

.error svg .glyph {
    fill: #fff;
}

.error .message {
    color: #fff;
}

.success .icon .border {
    stroke: #fcd669;
}

.success .icon .checkmark {
    stroke: #fff;
}

.success .title {
    color: #fff;
}

.success .message {
    color: #9cabc8;
}

.success .reset path {
    fill: #fff;
}
</style>
